body {
    font-family: "Arial", sans-serif;
}

.bar {
    fill: #5f89ad;
}

.axis {
    font-size: 13px;
}

.axis path,
.axis line {
    fill: none;
    display: none;
}

.label {
    font-size: 13px;
}