html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
    box-sizing: inherit;
}

body {
    background: color-bg(body);
    /* Fallback for when there is no custom background color defined. */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

hr {
    border: 0;
    @include divider();
    margin-top: $spacing--shared-v;
    margin-bottom: $spacing--shared-v;
}

ul,
ol {
    margin-top: 0;
    padding-left: $spacing--shared-h;
    &:not(:last-child) {
        margin-bottom: $spacing--shared-v;
    }
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

li {
    &:not(:last-child) {
        margin-bottom: $spacing--shared-v / 2;
    }
}

li>ul,
li>ol {
    margin-top: $spacing--shared-v / 2;
    margin-bottom: 0;
}

dl {
    margin-top: 0;
    margin-bottom: $spacing--shared-v;
}

dt {
    @include font-weight(strong);
}

dd {
    margin-left: $spacing--shared-h;
    margin-bottom: $spacing--shared-v;
}

img {
    border-radius: $img-radius;
}

img,
svg,
video {
    display: block;
    height: 100%;
    /* Make sure images are scaled correctly. */
    /* Update on 11/3: changed height:auto to 100% as it is messing up with d3 components*/
    max-width: 100%;
    /* Adhere to container width. */
}

figure {
    margin: $spacing--figure-v $spacing--figure-h;
    /* Extra wide images within figure tags don't overflow the content area. */
}

figcaption {
    padding: $spacing--figcaption-v $spacing--figcaption-h;
}

// tables
table {
    @include font-size(table);
    border-collapse: collapse;
    margin-bottom: $spacing--shared-v;
    width: 100%;
}

tr {
    border-bottom: 1px solid color-border(divider);
}

th {
    @include font-weight(strong);
    text-align: left;
}

th,
td {
    padding: $spacing--table-cell-v $spacing--table-cell-h;
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }
}

.invert-color {
    hr {
        @include divider(false, inverse);
    }
    tr {
        border-bottom: 1px solid color-border(divider-inverse);
    }
}